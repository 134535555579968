import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import tweet from "../assets/img/elon_tweet.PNG";
import credits from "../assets/img/game_credits_circled.jpg";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import ListGroup from 'react-bootstrap/ListGroup';

/*

      <li>Contract audits show no warnings</li>
      */

export const Lore = () => {

  return (
    <section className="token" id="lore">
      
      <h1>{`Lore`}</h1>
      <br/>
      <br/>
      <Container>
        <Row className="aligh-items-center">
        <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
            {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img src={tweet} alt="Header Img"/>
                </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img src={credits} alt="Header Img"/>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
