import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/header-img.svg";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import ListGroup from 'react-bootstrap/ListGroup';

/*

      <li>Contract audits show no warnings</li>
      */

export const Tokenomics = () => {

  return (
    <section className="token" id="tokenomics">
      <Container>
        <h1> Tokenomics</h1>
      <br/>
      <br/>
        <h2>
      <ul>
      <li>Token supply is 9,000,000,005 to symbolise the 9-5 workday</li>
      <li>Ownership renounced</li>
      <li>LP tokens locked</li>
      <li>No taxes</li>
      <li>No inflation</li>
      <li>No presale</li>
      <li>No BS</li>
    </ul>
    </h2>

      </Container>
    </section>
  )
}
