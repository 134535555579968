import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
/*
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
*/

import projImg1 from "../assets/img/webuildarocket.jpg";
import projImg2 from "../assets/img/buyout.jpg";
import projImg3 from "../assets/img/elon_sugars.png";
import projImg4 from "../assets/img/brainmeme.jpg";
import projImg5 from "../assets/img/memcoin.jpg";
import projImg6 from "../assets/img/combined_meme.jpg";


import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Memes = () => {

  const memes = [
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg1,
    },
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg2,
    },
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg3,
    },
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg4,
    },
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg5,
    },
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg6,
    }
  ];

  return (
    <section className="meme" id="memes">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div>
                <h2>Memes</h2>
      <br/>
      <br/>
                <p></p>
                <Row>
                        {
                          memes.map((meme, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...meme}
                                />
                            )
                          })
                        }
                      </Row>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
