import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/internelon.png";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Banner = () => {

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
        <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img src={headerImg} alt="Header Img"/>
                </div>}
            </TrackVisibility>
          </Col>
        <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
              
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <h1>{`Intern Elon`}</h1>
                
                <span className="tagline">#1 Elon Memecoin</span>
                  <p>
                  $InternElon represents Elon's life before he founded Tesla and Zip2. 
                  He interned at Rocket Science Company, creating a video game called Loadstar. This is where he learned to be a developer. 
                  He reminsces about this period on 𝕏.</p>
                  <p> 
                  It was a happy time in Elon's life.</p>
                  
                <a href='https://app.uniswap.org/swap?inputCurrency=0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2&outputCurrency=0xd59e1c137c3e13bb4501c3a17ab0fa3e3c4c8614'>
                <button className="vvd"><span>Buy at Uniswap <ArrowRightCircle size={25} /></span></button>
                </a>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
